import { apiUrl } from '@fava/Utils';
import { makeRequest } from '@fava/Utils/api';

const qs = require('qs');

const fetchClass = async (id: number, type: string) => {
  let filter: any = {
    establishment: {
      establishment_admins: {
        id: id as any,
      },
    },
  };

  if (type === 'structure_admin') {
    filter = {};
  }

  if (type === 'professor_user') {
    filter = {
      professor_user: {
        id: id as number,
      },
    };
  }

  const params = qs.stringify(
    {
      filters: filter,
      populate: ['level'],
      sort: ['createdAt:desc'],
    },
    {
      encodeValuesOnly: true,
    }
  );

  const response: any = await makeRequest(`${apiUrl}/api/classes?${params}`, 'GET', {});

  return response.data;
};

const fetchClassPopulate = async (id: number, type: string, account?: any) => {
  let filter: any = {
    establishment: {
      establishment_admins: {
        id: id as any,
      },
    },
  };

  if (type === 'structure_admin') {
    filter = {
      establishment: {
        id: id as any,
      },
    };
  }

  if (type === 'professor_user') {
    filter = {
      professor_user: {
        id: id as number,
      },
    };
  }

  console.log('filter', filter);
  console.log('type', type);
  console.log('account', account);
  const params = qs.stringify(
    {
      filters: filter,
      populate: ['professor_user', 'student_users', 'level'],
      sort: ['createdAt:desc'],
    },
    {
      encodeValuesOnly: true,
    }
  );

  const localUserInfos = localStorage.getItem('local_user_infos');
  let garEanList: string[] = [];

  let parsed: any = null;
  if (localUserInfos) {
    try {
      parsed = JSON.parse(localUserInfos);
      if (typeof parsed === 'string') {
        parsed = JSON.parse(parsed);
      }
    } catch (err) {
      console.error('❌ Erreur lors du parsing du localStorage:', err);
    }
  }

  if (type === 'professor_user' && !filter.professor_user?.id && parsed?.gar_ean) {
    garEanList = parsed.gar_ean
      .split(',')
      .map((ean: string) => ean.trim())
      .filter((ean: string) => ean !== '');
  }

  let query = `${apiUrl}/api/classes?populate[0]=professor_user&populate[1]=student_users&populate[2]=level&sort[0]=createdAt%3Adesc`;

  if (garEanList.length > 0) {
    garEanList.forEach((ean, index) => {
      query += `&filters[establishment][name][$in][${index}]=${encodeURIComponent(ean)}`;
    });

    const response: any = await makeRequest(query, 'GET', {});
    return response.data;
  }

  const response: any = await makeRequest(`${apiUrl}/api/classes?${params}`, 'GET', {});
  return response.data;
};

const createClasseRequest = async (data: any) => {
  const params = qs.stringify(
    {
      populate: ['professor_user', 'student_users', 'level'],
    },
    {
      encodeValuesOnly: true,
    }
  );

  const response: any = await makeRequest(`${apiUrl}/api/classes?${params}`, 'POST', {
    data: {
      ...data,
    },
  });

  return response.data;
};

const EditClasseRequest = async (data: any) => {
  const params = qs.stringify(
    {
      populate: ['professor_user', 'student_users', 'level'],
    },
    {
      encodeValuesOnly: true,
    }
  );

  const response: any = await makeRequest(`${apiUrl}/api/classes/${data.id}?${params}`, 'PUT', {
    data: {
      ...data,
    },
  });

  return response.data;
};

const fetchLevel = async () => {
  const response: any = await makeRequest(`${apiUrl}/api/levels`, 'GET', {});
  return response.data;
};

const deleteClasse = async (id: number) => {
  const response: any = await makeRequest(`${apiUrl}/api/classes/${id}`, 'DELETE', {});
  return response.data;
};

export {
  createClasseRequest,
  deleteClasse,
  EditClasseRequest,
  fetchClass,
  fetchClassPopulate,
  fetchLevel,
};
